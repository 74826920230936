<template>
  <div v-show="!isHidden" :class="CSSClasses" :style="computedStyle">
    <el-form-item
      :prop="name"
      :label="label"
      :class="elFormCssClasses"
      :style="elFormCss"
      :rules="[
        { required: _isRequired, message: $locale.main.message.required_field, trigger: 'blur' }
      ]"
    >
      <span slot="label" :class="labelCssClasses" :style="labelCss">
        {{ label }}
        <el-tooltip v-if="tooltip" class="item" effect="dark" :content="tooltip">
          <i class="el-icon-question"></i>
        </el-tooltip>
      </span>
    <gar-address-field
      :level="level"
      :placeholder="placeholder"
      :value="address"
      :required-level="requiredLevel"
      :default-address="defaultAddress"
      :type="type"
      @input="$emit('input', $event)"
    ></gar-address-field>
    </el-form-item>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import VisibleMixin from '../visible_properties_mixin'
import registryMixin from './registry_mixins'
import InputLabel from '@/mixins/inputLabel.js'

import GarAddressField from '@/core/infrastructure/components/GarAddressField'

export default {
  mixins: [mixin, VisibleMixin, registryMixin, InputLabel],
  components: { GarAddressField },
  name: 'gar_address_field',
  props: {
    registry_properties: {
      type: Array,
      frozen: true
    },
    label: {
      type: String,
      description: 'name'
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    },
    tooltip: {
      type: String,
      description: 'tooltip'
    },
    defaultAddress: {
      type: String,
      description: 'default_address'
    },
    requiredLevel: {
      type: String,
      description: 'required_level',
      editor: 'Select',
      options: {
        options: [
          { id: 'subject', name: 'Регион' },
          { id: 'area', name: 'Район' },
          { id: 'city', name: 'Город' },
          { id: 'locality', name: 'Населенный пункт' },
          { id: 'planning_structure', name: 'Планировочная структура' },
          { id: 'street', name: 'Улица' },
          { id: 'house', name: 'Дом' },
          { id: 'flat', name: 'Квартира/Помещение' },
          { id: 'description', name: 'Описание' }
        ]
      }
    }
  },
  data () {
    return {
      address: {}
    }
  },
  computed: {
    type () {
      return (this.registry_properties.find(item => item.id === 'address_type') || {}).value
    },
    level () {
      return parseInt((this.registry_properties.find(item => item.id === 'object_level') || {}).value)
    },
    computedStyle () {
      let css = this.CSS
      if (this.align) {
        css += ';text-align:' + this.align
      }
      if (this.margin) {
        css += ';margin:' + this.margin
      }
      if (this.width && !this.block) {
        css += ';width:' + this.width
      }
      if (!this.block) {
        css += `;display: inline-block; width:${this.width || '200px'}`
      }
      if (this.wrapper) {
        css += ';display: block;'
      }

      return css
    }
  },
  async mounted () {
    await this.parseValue()
  },
  methods: {
    async parseValue () {
      if (!this.value) {
        return false
      }
      let parsed = this.value
      try {
        parsed = JSON.parse(this.value)
      } catch (e) {
      }
      if (typeof parsed === 'object') {
        this.address = await this.getAddressById(parsed.id || parsed[0].id)
      } else {
        this.address = await this.getAddressById(parsed[0] || parsed)
      }
    },
    async getAddressById (addressId) {
      if (!addressId) {
        return {}
      }

      let data = await this.$http.get(`${this.$config.api}/garaddressservice/addresses?id=${addressId}`)
      return data.data ? Object.assign(data.data[0], {
        name: data.data[0].address,
        address_id: data.data[0].id
      }) : {}
    }
  }
}
</script>

<style scoped>

</style>
